<template>
  <div>
    <div class="mt-3 card-container">
      <CreditCard :number="user.financialData.refBankIban.replace(/\s/g, '').slice(-4)" />
      <h2 class="font-weight-medium">{{ $t('views.Withdraw.amount.question') }}</h2>
      <div class="d-flex justify-space-between grey--text mt-7">
        <span>{{ $t('views.Withdraw.amount.currentBalance') }}</span>
        <span>{{ $n(balance.spendableCashBalance, 'floatCurrency') }}</span>
      </div>
      <div class="d-flex justify-space-between mt-3">
        <span>{{ $t('views.Withdraw.amount.desiredAmount') }}</span>
        <span>{{ $n(amount, 'floatCurrency') }}</span>
      </div>
      <v-form
        v-model="isFormValid"
        @submit.prevent="nextStep"
        class="mt-5"
      >
        <v-text-field
          id="amount"
          class="amountErrorMessage"
          :rules="rulesList"
          :label="`${$t('views.Withdraw.amount.inputLabel')} *`"
          required
          outlined
          v-model="displayAmount"
          @keydown="nameKeydown"
        ></v-text-field>
      </v-form>
      <v-btn
        color="primary"
        class="black--text"
        @click="nextStep"
        :disabled="!isFormValid"
      >
        {{ $t('views.Withdraw.amount.next') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/plugins/i18n';
import lazyload from '@/utils/lazyLoad';

export default {
  name: 'Amount',
  components: {
    CreditCard: lazyload('components/Shared/Cards/CreditCard'),
  },
  data() {
    return {
      rulesList: [
        (v) => !!v || i18n.t('validators.required'),
        (v) => Number(v?.replace(/[^0-9-]+/g, '')) / 100 > 0 || i18n.t('validators.required'),
      ],
      isFormValid: false,
      displayAmount: null,
      amount: 0,
    };
  },
  computed: {
    ...mapGetters('user', [
      'balance',
      'user',
    ]),
  },
  watch: {
    displayAmount(newVal) {
      const amount = Number(newVal.replace(/[^0-9-]+/g, '')) / 100;
      this.displayAmount = this.$n(amount, 'float');
      this.amount = amount;
      this.setAmount(amount);
    },
  },
  created() {
    this.rulesList.push(
      (v) => Number(v?.replace(/[^0-9-]+/g, '')) / 100 <= this.balance.spendableCashBalance
        || i18n.t('validators.withdrawAmount'),
    );
  },
  methods: {
    ...mapActions('withdraw', [
      'reset',
      'nextStep',
      'previousStep',
      'setAmount',
    ]),
    nameKeydown(e) {
      if (/^[^0-9-]+$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Enter') {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  width: 500px;
}
@media (max-width: 600px) {
  .card-container {
    width: 100%;
  }
}
</style>
